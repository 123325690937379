import React from "react";
import { Link, useLocation } from "react-router-dom";
import logo from "../../assets/cognismiles-logo.png"



const Footer = ({selectedOption}) => {
  const location = useLocation();
  return (
    <>
      <footer className={`bg-secondory ${location.pathname === '/referrals' && location.pathname === '/surgical-guides' ? 'mt-0' : 'mt-24'}mt-24  py-16 px-5 md:px-16`}>
        <div className="md:grid-cols-4 grid-cols-1 grid gap-10 items-center">
          <div className="">
            {/* first col */}
            <div className="">
            <Link to="/">
            <img src={logo} alt="logo" width={90} />
          </Link>
              <p className="">
                At our practices we always provide the best dental care
                with a patient-centred approach.
              </p>
              <div className="flex gap-6 mt-7">
                <a href="https://www.instagram.com/rosebroughdentalpractice/" target="_blank" rel="noreferrer">
                <svg
                  className="w-7 h-7 text-primary hover:text-tertiary  cursor-pointer"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                >
                  <g>
                    <path d="M0 0h24v24H0z" fill="none" />
                    <path
                      d="M12 2c2.717 0 3.056.01 4.122.06 1.065.05 1.79.217 2.428.465.66.254 1.216.598 1.772 1.153a4.908 4.908 0 0 1 1.153 1.772c.247.637.415 1.363.465 2.428.047 1.066.06 1.405.06 4.122 0 2.717-.01 3.056-.06 4.122-.05 1.065-.218 1.79-.465 2.428a4.883 4.883 0 0 1-1.153 1.772 4.915 4.915 0 0 1-1.772 1.153c-.637.247-1.363.415-2.428.465-1.066.047-1.405.06-4.122.06-2.717 0-3.056-.01-4.122-.06-1.065-.05-1.79-.218-2.428-.465a4.89 4.89 0 0 1-1.772-1.153 4.904 4.904 0 0 1-1.153-1.772c-.248-.637-.415-1.363-.465-2.428C2.013 15.056 2 14.717 2 12c0-2.717.01-3.056.06-4.122.05-1.066.217-1.79.465-2.428a4.88 4.88 0 0 1 1.153-1.772A4.897 4.897 0 0 1 5.45 2.525c.638-.248 1.362-.415 2.428-.465C8.944 2.013 9.283 2 12 2zm0 5a5 5 0 1 0 0 10 5 5 0 0 0 0-10zm6.5-.25a1.25 1.25 0 0 0-2.5 0 1.25 1.25 0 0 0 2.5 0zM12 9a3 3 0 1 1 0 6 3 3 0 0 1 0-6z"
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                    />
                  </g>
                </svg>
                </a>

                <a href="https://www.facebook.com/roseboroughdentalpractice/" target="_blank" rel="noreferrer">
                <svg
                  className="w-7 h-7 text-primary hover:text-tertiary cursor-pointer"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill-rule="evenodd"
                    d="M13.1 6H15V3h-1.9A4.1 4.1 0 0 0 9 7.1V9H7v3h2v10h3V12h2l.6-3H12V6.6a.6.6 0 0 1 .6-.6h.5Z"
                    clip-rule="evenodd"
                  />
                </svg>
                </a>

                {/* <svg
                  className="w-7 h-7 text-primary cursor-pointer"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill-rule="evenodd"
                    d="M22 5.9c-.7.3-1.5.5-2.4.6a4 4 0 0 0 1.8-2.2c-.8.5-1.6.8-2.6 1a4.1 4.1 0 0 0-6.7 1.2 4 4 0 0 0-.2 2.5 11.7 11.7 0 0 1-8.5-4.3 4 4 0 0 0 1.3 5.4c-.7 0-1.3-.2-1.9-.5a4 4 0 0 0 3.3 4 4.2 4.2 0 0 1-1.9.1 4.1 4.1 0 0 0 3.9 2.8c-1.8 1.3-4 2-6.1 1.7a11.7 11.7 0 0 0 10.7 1A11.5 11.5 0 0 0 20 8.5V8a10 10 0 0 0 2-2.1Z"
                    clip-rule="evenodd"
                  />
                </svg> */}
                 {/* <a href="https://www.instagram.com/rosebroughdentalpractice/" target="_blank" rel="noreferrer">
                <svg
                  className="w-7 h-7 text-primary hover:text-tertiary cursor-pointer"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill-rule="evenodd"
                    d="M21.7 8c0-.7-.4-1.3-.8-2-.5-.5-1.2-.8-2-.8C16.2 5 12 5 12 5s-4.2 0-7 .2c-.7 0-1.4.3-2 .9-.3.6-.6 1.2-.7 2l-.2 3.1v1.5c0 1.1 0 2.2.2 3.3 0 .7.4 1.3.8 2 .6.5 1.4.8 2.2.8l6.7.2s4.2 0 7-.2c.7 0 1.4-.3 2-.9.3-.5.6-1.2.7-2l.2-3.1v-1.6c0-1 0-2.1-.2-3.2ZM10 14.6V9l5.4 2.8-5.4 2.8Z"
                    clip-rule="evenodd"
                  />
                </svg>
                </a> */}
              </div>
            </div>
          </div>
          {/* second col */}
          <div className="flex items-center md:gap-10 gap-5 flex-wrap col-span-2">
            <Link to="/">
              <h2 className="text-primary font-semibold hover:border-b-[1px] border-primary">Home</h2>
            </Link>
            <Link  to="/about-us">
              <h2 className="text-primary font-semibold hover:border-b-[1px] border-primary">About Us</h2>
            </Link>
            <Link to="/dental-implants">
              <h2 className="text-primary font-semibold hover:border-b-[1px] border-primary">Dental Implants</h2>
            </Link>
            <Link to="/invisalign">
              <h2 className="text-primary font-semibold hover:border-b-[1px] border-primary">Invisalign</h2>
            </Link>
            <Link to="/private-fee">
              <h2 className="text-primary font-semibold hover:border-b-[1px] border-primary">Private Fee</h2>
            </Link>
            <Link to="/nhs-fee">
              <h2 className="text-primary font-semibold hover:border-b-[1px] border-primary">NHS Fee</h2>
            </Link>
            <Link to="/denplan">
              <h2 className="text-primary font-semibold hover:border-b-[1px] border-primary">Denplan</h2>
            </Link>
            {/* <Link to="/book-appointments">
              <h2 className="text-primary font-semibold hover:border-b-[1px] border-primary">Book Appointment</h2>
            </Link> */}
            <Link to="/treatments">
              <h2  className="text-primary font-semibold hover:border-b-[1px] border-primary">Treatments</h2>
            </Link>
            <Link to="/contact-us">
              <h2 className="text-primary font-semibold hover:border-b-[1px] border-primary">Contact Us</h2>
            </Link>
          </div>
          {/* Third col */}
          <div className="flex flex-col gap-7 md:items-center items-star">
            <Link to="/our-courses">
              {" "}
              <div className="w-48 cursor-pointer font-medium rounded-md text-center bg-tertiary text-white px-3 py-2 border border-tertiary">
                Courses For Dentist
              </div>
            </Link>
            <Link to="/referrals">
              <div className="cursor-pointer w-48 font-medium rounded-md text-center bg-transparent text-primary border-primary border-2 px-3 py-2">
                Referrals
              </div>
            </Link>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
